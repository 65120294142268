import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
    //display: inline-flex;
    //flex-wrap: wrap;
    //align-items: center;
    //transition: opacity 0.25s ease;

    display: block;
    min-width: 100%;

    ${(props) =>
        props.deleted === true &&
        css`
            opacity: 0.5;
        `}

    ${(props) =>
        props.highlight &&
        css`
            background: rgba(200, 70, 250, 0.5);
            border: 1.5px solid rgba(144, 5, 199, 0.5);
            padding: 10px;
            border-radius: 12px;
            max-width: 100%;
            margin-bottom: 10px;
        `}
`;

const Username = styled.span`
    color: ${(props) => props.color};
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: 800;
    font-size: 18px;
`;

const Msg = styled.p`
    margin: 12px;
    margin-left: 0.5rem;
    font-size: 18px;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    color: #fff;

    font-weight: 600;
    //display: flex;
    display: block;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.25rem;
`;

const Link = styled.a`
    color: #8490ff;
    margin-left: 5px;
    text-decoration: none;
    font-weight: 600;
`;

const Badges = styled.div`
    display: inline;
    user-select: none;
`;

const Badge = styled.div`
    display: inline;
    margin-right: 5px;
    & img {
        display: inline;
        width: 20px;
        height: 20px;
    }
`;

const ChannelIcon = styled.div`
    & img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 0.75rem;
        user-select: none;
    }
`;

const EmoteContainer = styled.div`
    display: inline-block;
    height: 18px;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 1.5px;
    margin-right: 1.5px;

    & img {
        vertical-align: middle;
        margin: -0.5rem 0;
        width: 100%;
        overflow: visible;
    }
`;

function Message({
    key,
    data,
    channel,
    showIcon,
    onClick,
    emoteOnly = false,
    big = false,
}) {
    return (
        <>
            <Container
                highlight={data.sub || false}
                key={key}
                deleted={data.deleted}
                onClick={onClick ? onClick : () => {}}
            >
                <span
                    style={{
                        float: "left",
                        display: "inline-flex",
                        height: big ? "29.5px" : "22.5px",
                        alignItems: "center",
                        marginRight: "2.5px",
                    }}
                >
                    {showIcon && channel && (
                        <ChannelIcon>
                            <img src={channel.logo} alt={channel.name} />
                        </ChannelIcon>
                    )}
                    <Badges>
                        {channel &&
                            !data.sub &&
                            data.tags &&
                            data.tags["badges"] &&
                            Object.keys(data.tags["badges"]).map((key, i) => {
                                if (
                                    channel.badges &&
                                    channel.badges[key][
                                        data.tags["badges"][key]
                                    ]
                                ) {
                                    return (
                                        <>
                                            <Badge>
                                                <img
                                                    src={
                                                        channel.badges[key][
                                                            data.tags["badges"][
                                                                key
                                                            ]
                                                        ]
                                                    }
                                                    alt={key}
                                                    key={key}
                                                />
                                            </Badge>
                                        </>
                                    );
                                }
                            })}
                        {data.tags &&
                            data.tags["username"] === "dieserobin" && (
                                <Badge>
                                    <img
                                        src="https://cdn.7tv.app/emote/60bf5b8d0d20f30c6896c5f8/2x.avif"
                                        alt="Coder"
                                    />
                                </Badge>
                            )}
                    </Badges>
                    <Username
                        style={big ? { fontSize: "24px" } : {}}
                        color={
                            !data.sub && data.tags && data.tags.color !== null
                                ? `${data.tags.color}`
                                : `#fff`
                        }
                    >
                        {!data.sub && data.tags && data.tags["display-name"]}
                        {!data.sub && `:`}
                        &nbsp;
                        {data.sub && (
                            <>
                                {data.subMessage}
                                {data.message && ":"}
                            </>
                        )}
                    </Username>
                </span>
                <Msg style={big ? { fontSize: "24px" } : {}}>
                    {" "}
                    {data.message &&
                        data.message.split(" ").map((arg) => {
                            if (
                                channel &&
                                channel.emotes &&
                                channel.emotes[arg]
                            ) {
                                return (
                                    <>
                                        <EmoteContainer>
                                            <img
                                                className="emote"
                                                alt={arg}
                                                src={channel.emotes[arg]}
                                            />
                                        </EmoteContainer>
                                    </>
                                );
                            } else if (arg.includes("//EMOTE-")) {
                                return (
                                    <>
                                        <EmoteContainer>
                                            <img
                                                className="emote"
                                                alt={arg}
                                                src={`https://static-cdn.jtvnw.net/emoticons/v2/${
                                                    arg.split("-")[1]
                                                }/default/dark/1.0`}
                                            />
                                        </EmoteContainer>
                                    </>
                                );
                            } else if (
                                arg.match(
                                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
                                ) &&
                                !emoteOnly
                            ) {
                                return (
                                    <>
                                        <Link
                                            rel="noreferrer"
                                            href={
                                                arg.includes("http")
                                                    ? arg
                                                    : `https://${arg}`
                                            }
                                            target="_blank"
                                        >
                                            {arg}
                                        </Link>
                                    </>
                                );
                            } else if (!emoteOnly) {
                                return <>{arg} </>;
                            }
                        })}
                </Msg>
            </Container>
        </>
    );
}

export default Message;
