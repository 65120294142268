import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { css } from "styled-components";
import tmi from "tmi.js";
import Message from "./components/Message";

import getUserId from "./functions/getUserId";
import getEmotes from "./functions/getEmotes";
import getBadges from "./functions/getBadges";
import NoAudio from "./assets/nosound.mp3";
import { useParams } from "react-router-dom";

function isASCII(str, extended) {
  return (extended ? /^[\x00-\xFF]*$/ : /^[\x00-\x7F]*$/).test(str);
}

const Pinned = styled.div`
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  padding: 5px;
  z-index: 98;
  padding-top: 3.5rem;
`;

const Channels = styled.div`
  width: 100%;
  height: 2.75rem;
  display: flex;
  padding-left: 5px;
  margin-top: 1rem;
  position: fixed;
  z-index: 99;
`;

const Channel = styled.div`
  width: fit-content;
  max-height: 100%;
  font-family: "Lexend", Arial, Helvetica, sans-serif;
  border-radius: 50px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  background-color: #282930;
  border: 2.5px solid #3e404b;
  transition: background-color 0.25s ease, border 0.25s ease;
  color: #fff;
  margin-right: 0.5rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #31323c;
    /* border: 2.5px solid #31323C; */
  }

  &:active {
    background-color: #212228;
    /* border: 2.5px solid #212228; */
  }

  ${(props) =>
    props.selectedChannel.toLowerCase() === props.channel.toLowerCase() &&
    css`
      background-color: #3e404b;
    `}
`;
const Button = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  user-select: none;
  background-color: #8490FF;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0px 0px 2.5px 2.5px rgba(0,0,0,.125);
  transition: transform .25s ease, background-color .25s ease;
  cursor: pointer;

  &:hover {
    background-color: #7482FF;
  }

  &:active {
    background-color: #606BCC;
  }

  ${(props) =>
    props.add &&
    css`
      width: 2rem;
      height: 2rem;
      position: fixed;
      bottom: 2rem;
      right: 2rem;
      user-select: none;
      background-color: #8490ff;
      padding: 1rem;
      border-radius: 50%;
      box-shadow: 0px 0px 2.5px 2.5px rgba(0, 0, 0, 0.125);
      transition: transform 0.25s ease, background-color 0.25s ease;
      cursor: pointer;
    `}}

  ${(props) =>
    props.open &&
    css`
      width: fit-content;
      position: fixed;
      top: 2.25rem;
      right: 2rem;
      user-select: none;
      background-color: #8490ff;
      padding: 0.5rem 1.5rem 0.5rem 1.5rem;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Lexend", Arial, Helvetica, sans-serif;
      box-shadow: 0px 0px 2.5px 2.5px rgba(0, 0, 0, 0.125);
      transition: transform 0.25s ease, background-color 0.25s ease;
      cursor: pointer;

      ${(props) =>
        props.overlay &&
        css`
          @media screen and (max-width: 650px) {
            display: none;
          }
        `}

      ${(props.channels.length <= 0 || props.channels.length > 20) &&
      css`
        background-color: #606bcc;
        cursor: not-allowed;
      `}
    `}
`;

const Messages = styled.div`
  width: calc(100vw - 2rem);
  height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: scroll;
`;

let audioContext = window.AudioContext || window.webkitAudioContext;
let context = new audioContext();

function TTS() {
  const [channels, setChannels] = useState(["revedtv"]);
  const [ttsQueue, setTtsQueue] = useState([]);
  const [messages, setMessages] = useState([]);
  const [pinned, setPinned] = useState(null);
  const [scroll, setScroll] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState("");
  const messagesListRef = useRef(null);
  const [isAudioStarted, setIsAudioStarted] = useState(false);

  const [client, setClient] = useState();
  const [channelsData, setChannelsData] = useState({});
  const [audioElement, setAudioElement] = useState(new Audio());

  const playSilentAudio = useCallback(() => {
    context.resume().then(() => {
      if (audioElement.src !== NoAudio) {
        audioElement.src = NoAudio;
        audioElement.loop = true;
        audioElement.play();
      } else {
        audioElement.play();
      }
    });
  }, [audioElement]);

  const playTTS = useCallback(
    (text) => {
      context.resume().then(() => {
        let ttsUrl = `https://api.streamelements.com/kappa/v2/speech?voice=Hans&text=${encodeURIComponent(
          text
        )}`;

        if (audioElement.src !== ttsUrl) {
          audioElement.src = ttsUrl;
          audioElement.onended = () => {
            console.log("TTS Ended");
            setTtsQueue((prevTtsQueue) => prevTtsQueue.slice(1));
            playSilentAudio();
          };
        }
        audioElement.loop = false; // Stop looping when TTS audio is played
        audioElement.play();
      });
    },
    [audioElement, playSilentAudio]
  );

  const addToTTSQueue = useCallback((text) => {
    setTtsQueue((prevTtsQueue) => [...prevTtsQueue, text]);
  }, []);

  useEffect(() => {
    if (ttsQueue.length > 0) {
      console.log("Processing TTS Queue");
      const text = ttsQueue[0];
      console.log("TTS Text:", text);
      playTTS(text);
    } else {
      playSilentAudio();
    }
  }, [ttsQueue, playTTS, playSilentAudio]);

  useEffect(() => {
    if (!client) {
      setClient(
        new tmi.Client({
          options: { debug: false, messagesLogLevel: "info" },
          connection: {
            reconnect: true,
            secure: true,
          },
          channels: channels,
        })
      );

      channels.map(async (channel) => {
        if (channel.startsWith("#")) {
          channel = channel.substring(1);
        }

        getUserId(channel).then((data) => {
          if (data) {
            setChannelsData((c) => {
              var temp = c;
              temp[channel] = {
                created_at: data["created_at"],
                display_name: data["display_name"],
                id: data["id"],
                bio: data["description"],
                logo: data["profile_image_url"],
                type: data["type"],
                name: data["display_name"],
              };

              return temp;
            });

            getEmotes(
              "y4sxj0tgauj514q76ru6wdwc9kxn7s",
              data["id"],
              data["display_name"]
            ).then((emotes) => {
              if (emotes) {
                setChannelsData((c) => {
                  var temp = c;
                  temp[channel]["emotes"] = emotes;

                  return temp;
                });
              }
            });

            getBadges(data["id"]).then((badges) => {
              if (badges) {
                setChannelsData((c) => {
                  var temp = c;
                  temp[channel]["badges"] = badges;

                  return temp;
                });
              }
            });
          }
        });
      });
    }

    return async () => {
      setClient(null);
    };
  }, [channels]);

  useEffect(() => {
    if (client) {
      client.connect().catch(console.error);

      client.on("timeout", (channel, username) => {
        setMessages((prev) => {
          return prev.filter((a) => a.tags["username"] !== username);
        });
      });

      client.on("ban", (channel, username) => {
        setMessages((prev) => {
          return prev.filter((a) => a.tags["username"] !== username);
        });
      });

      client.on(
        "subscription",
        (channel, username, method, message, userstate) => {
          setMessages((msgs) =>
            msgs.concat({
              channel: channel,
              subMessage: `${username} has subscribed with ${method.plan}`,
              message: message,
              deleted: false,
              sub: true,
              tags: {},
            })
          );
        }
      );

      client.on(
        "resub",
        (channel, username, months, message, userstate, methods) => {
          setMessages((msgs) =>
            msgs.concat({
              channel: channel,
              subMessage: `${username} has subscribed for ${months} Months with ${methods.plan}`,
              message: message,
              deleted: false,
              sub: true,
              tags: {},
            })
          );
        }
      );

      client.on(
        "subgift",
        (channel, username, streakMonths, recipient, methods, userstate) => {
          setMessages((msgs) =>
            msgs.concat({
              channel: channel,
              subMessage: `${username} has gifted a sub to ${recipient}`,
              message: "",
              deleted: false,
              sub: true,
            })
          );
        }
      );

      client.on("message", (channel, tags, message, self) => {
        var badgeInfo = tags["badge-info"];
        var badgesInfoRaw = tags["badge-info-raw"];
        var badges = tags["badges"];
        var badgesRaw = ["badges-raw"];
        var color = tags["color"];
        var displayName = tags["display-name"];
        var twitchEmotes = tags["emotes"];
        var flags = tags["flags"];
        var id = tags["id"];
        var messageType = tags["message-type"];
        var mod = tags["mod"];
        var roomId = tags["room-id"];
        var subscriber = tags["subscriber"];
        var timestamp = tags["tmi-sent-ts"];
        var turbo = tags["turbo"];
        var userId = tags["user-id"];
        var userType = tags["user-type"];
        var username = tags["username"];

        var formattedMsg = message;

        if (twitchEmotes) {
          Object.keys(twitchEmotes).map((tw, index) => {
            var target = message
              .split("")
              .slice(
                Number(tags.emotes[tw][0].split("-")[0]),
                Number(tags.emotes[tw][0].split("-")[1]) + 1
              );
            twitchEmotes[tw].map((e, i) => {
              if (!Number(twitchEmotes[tw][i].split("-")[0] + 1)) {
                return;
              }
              formattedMsg = formattedMsg.replace(
                target.join(""),
                ` //EMOTE-${Object.keys(twitchEmotes)[index]} `
              );
            });
          });
        }

        if (username === "dieserobin" || mod) {
          if (message.split(" ")[0] === "@pin") {
            setPinned({
              channel: channel,
              tags: tags,
              message: formattedMsg.slice(4, formattedMsg.length),
              self: self,
            });
          } else if (message.split(" ")[0] === "@unpin") {
            setPinned(null);
          }
        }

        if (isASCII(message, true)) {
          if (
            message.split(" ")[0] === "@play" &&
            (mod ||
              username == "dieserobin" ||
              username == "xniklasmc" ||
              username == "swabine" ||
              username == "jonacrn" ||
              username == "trace2509")
          ) {
            try {
              const filteredMessage = message
                .split(" ")
                .filter((word) => {
                  if (word.startsWith("//EMOTE")) return false;
                  if (word.startsWith("@play")) return false;
                  return true;
                })
                .join(" ");

              addToTTSQueue(
                `${displayName
                  .replace("xNiklasMC", "niklas")
                  .replace("dieserobin", "robin")
                  .replace("jonacrn", "jona")
                  .replace("trace2509", "trace")} sagt ${filteredMessage}`
              );
            } catch (e) {
              console.log(e);
            }
          }

          setMessages((msgs) =>
            msgs.concat({
              channel: channel,
              tags: tags,
              message: formattedMsg,
              self: self,
              deleted: false,
            })
          );
        }
      });
    }

    return async () => {
      if (client) {
        client.removeAllListeners();
        client.disconnect();
      }
    };
  }, [client, addToTTSQueue]);

  useEffect(() => {
    if (scroll) {
      messagesListRef.current?.scrollTo(
        0,
        messagesListRef.current?.scrollHeight
      );
    } else if (document.body.classList.contains("overlay")) {
      messagesListRef.current?.scrollTo(
        0,
        messagesListRef.current?.scrollHeight
      );
    }

    if ((messages.length > 50 && scroll) || messages.length > 150) {
      setMessages((m) => {
        m.shift();
        return m;
      });
    }
  }, [messages]);

  const startAudio = useCallback(() => {
    setIsAudioStarted(true);
    playSilentAudio();
  }, [playSilentAudio]);

  useEffect(() => {
    const audio = new Audio();
    setAudioElement(audio);

    const handleInteraction = () => {
      audio.src = NoAudio;
      audio.loop = true;
      audio
        .play()
        .then(() => {
          setIsAudioStarted(true);
          window.removeEventListener("click", handleInteraction);
          window.removeEventListener("touchstart", handleInteraction);
        })
        .catch((error) => {
          console.error("Failed to play silent audio: ", error);
        });
    };

    window.addEventListener("click", handleInteraction);
    window.addEventListener("touchstart", handleInteraction);

    return () => {
      window.removeEventListener("click", handleInteraction);
      window.removeEventListener("touchstart", handleInteraction);
    };
  }, []);

  return (
    <>
      {pinned && (
        <Pinned>
          <Message
            data={pinned}
            channel={
              channelsData[pinned.channel.slice(1, pinned.channel.length)]
            }
          />
        </Pinned>
      )}
      <Messages
        ref={messagesListRef}
        onScroll={(e) => {
          if (
            e.target.scrollTop <
            e.target.scrollHeight - e.target.clientHeight - 50
          ) {
            setScroll(false);
          } else {
            setScroll(true);
          }
        }}
      >
        <Button
          style={{
            transition: "opacity .25s ease",
            opacity: !scroll ? 1 : 0,
            zIndex: 9999999,
          }}
          onClick={(e) => {
            setScroll(true);
            messagesListRef.current?.scrollTo(
              0,
              messagesListRef.current?.scrollHeight
            );
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 14l-7 7m0 0l-7-7m7 7V3"
            />
          </svg>
        </Button>
        <React.Fragment>
          {messages.map((message) => {
            if (
              selectedChannel &&
              selectedChannel.toLowerCase() ===
                message.channel.toLowerCase().slice(1, message.channel.length)
            ) {
              return (
                <Message
                  key={message.id}
                  data={message}
                  onClick={() => {
                    console.log("CLICKED");
                    addToTTSQueue(
                      `${message.tags.username} sagt ${message.message}`
                    );
                  }}
                  channel={
                    channelsData[
                      message.channel.slice(1, message.channel.length)
                    ]
                  }
                  showIcon={channels.length > 1 ? true : false}
                ></Message>
              );
            } else if (selectedChannel === "") {
              return (
                <Message
                  key={message.id}
                  data={message}
                  onClick={() =>
                    addToTTSQueue(
                      `${message.tags.username} sagt ${message.message}`
                    )
                  }
                  channel={
                    channelsData[
                      message.channel.slice(1, message.channel.length)
                    ]
                  }
                  showIcon={channels.length > 1 ? true : false}
                ></Message>
              );
            }
          })}
        </React.Fragment>
      </Messages>
    </>
  );
}

export default TTS;
