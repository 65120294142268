function getBadges(channelId) {
  var promise = new Promise(async (resolve, reject) => {
    var badges = {};

    console.info(`[TWITCH] Fetching Global Badges`);
    await fetch(`https://chatapi.robin.software/badges/global`, {})
      .then((res) => res.json())
      .then((res) => {
        if (res && res["data"]) {
          res.data.forEach((badge) => {
            badges[badge.set_id] = {};
            badge.versions.forEach((version) => {
              badges[badge.set_id][version.id] = version.image_url_2x;
            });
          });
        } else {
          console.error("[TWITCH] No valid response");
          reject("No valid response");
        }
      })
      .catch((e) => {
        console.error(`[TWITCH] Error at fetching Badges`, e);
        reject("Error at fetching Badges");
      });

    console.info(`[TWITCH] Fetching Badges of ${channelId}`);
    await fetch(`https://chatapi.robin.software/badges/${channelId}`, {})
      .then((res) => res.json())
      .then((res) => {
        if (res && res["data"]) {
          res.data.forEach((badge) => {
            badges[badge.set_id] = {};
            badge.versions.forEach((version) => {
              badges[badge.set_id][version.id] = version.image_url_2x;
            });
          });
        } else {
          console.error("[TWITCH] No valid response");
          reject("No valid response");
        }
      })
      .catch((e) => {
        console.error(`[TWITCH] Error at fetching Badges`, e);
        reject("Error at fetching Badges");
      });

    resolve(badges);
  });

  return promise;
}

export default getBadges;
