import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RemoveIcon from "../assets/svg/remove.svg";

const Popup = styled.div`
    padding: 1rem;
    background-color: #282930;
    border: 2.5px solid #3e404b;
    border-radius: 10px;
    user-select: none;
    transition: background-color 0.25s ease;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 25rem;
    height: 15rem;
    z-index: 20;
    transition: background-color 0.25s ease, transform 0.25s ease,
        opacity 0.25s ease;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.125);

    @media (max-width: 650px) {
        width: 85vw !important;
        height: 15.75rem;
    }
`;

const Title = styled.h3`
    margin: 0;
    color: #fff;
    font-size: 24px;
    font-family: "Lexend", Arial, Helvetica, sans-serif;
    font-weight: 600;
    user-select: none;
    margin-top: 0.25rem;
`;
const Close = styled.div`
    position: absolute;
    right: 1rem;
    top: 1.25rem;
    width: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #282930;
    padding: 0.5rem;
    user-select: none;
    cursor: pointer;
    border-radius: 50%;
    border: 1.5px solid #363741;

    transition: background 0.25s ease, box-shadow 0.25s ease;

    &:hover {
        background-color: #363741;
    }

    &:active {
        box-shadow: 0px 0px 5px 5px rgba(54, 55, 65, 0.25);
    }
`;
const Content = styled.div`
    width: 100%;
    height: calc(100% - 3.5rem);
    margin-top: 1rem;
    border-radius: 5px;
`;

const Input = styled.input`
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border: none;
    border-radius: 8px;
    width: calc(100% - 1rem);
    font-size: 24px;
    color: #fff;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
    transition: background-color 0.25s ease, box-shadow 0.25s ease;
    background-color: #282930;
    border: 2.5px solid #3e404b;
    outline: none;

    &:hover {
        background-color: #31323c;
        /* border: 2.5px solid #31323C; */
    }

    &:active,
    &:focus {
        background-color: #212228;
        /* border: 2.5px solid #212228; */
    }
`;

const Suggestions = styled.div`
    width: 100%;
    height: 2.75rem;
    display: flex;
    margin-top: 1rem;
`;

const Suggestion = styled.div`
    width: fit-content;
    max-height: 100%;
    font-family: "Lexend", Arial, Helvetica, sans-serif;
    border-radius: 50px;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    background-color: #282930;
    border: 2.5px solid #3e404b;
    transition: background-color 0.25s ease, border 0.25s ease;
    color: #fff;
    margin-right: 0.5rem;
    cursor: pointer;

    &:hover {
        background-color: #31323c;
        /* border: 2.5px solid #31323C; */
    }

    &:active {
        background-color: #212228;
        /* border: 2.5px solid #212228; */
    }
`;

const Button = styled.div`
    /* width: 2rem;
    height: 2rem; */
    user-select: none;
    background-color: #8490ff;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    font-family: "Lexend", Arial, Helvetica, sans-serif;
    margin-top: 1.5rem;
    box-shadow: 0px 0px 2.5px 2.5px rgba(0, 0, 0, 0.125);
    transition: transform 0.25s ease, background-color 0.25s ease;

    &:hover {
        background-color: #7482ff;
    }

    &:active {
        background-color: #606bcc;
    }
`;

function AddPopup({ close, addChannel }) {
    const [channel, setChannel] = useState("");

    const [suggestions, setSuggestions] = useState([
        "Papaplatte",
        "Reeze",
        "RevedTV",
    ]);

    return (
        <>
            <Popup>
                <Title>Add Channel</Title>
                <Close onClick={close} className="close">
                    <img src={RemoveIcon} alt="close"></img>
                </Close>

                <Suggestions>
                    {suggestions.map((suggestion) => {
                        return (
                            <Suggestion
                                key={suggestion}
                                onClick={(e) => setChannel(suggestion)}
                            >
                                {suggestion}
                            </Suggestion>
                        );
                    })}
                </Suggestions>

                <Content>
                    <Input
                        placeholder="Channel name"
                        value={channel}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                if (!channel || channel.length < 4) {
                                    return;
                                }
                                addChannel(channel);
                            }
                            if (e.key === " ") {
                                e.stopPropagation();
                                e.preventDefault();
                            }

                            if (channel.length > 25) {
                                e.stopPropagation();
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            setChannel(e.target.value);
                        }}
                    ></Input>
                    <Button
                        onClick={(e) => {
                            if (!channel || channel.length < 4) {
                                return;
                            }
                            addChannel(channel);
                        }}
                    >
                        Add
                    </Button>
                </Content>
            </Popup>
        </>
    );
}

export default AddPopup;
