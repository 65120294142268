import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import tmi from "tmi.js";
import Message from "./Message";

import getUserId from "../functions/getUserId";
import getEmotes from "../functions/getEmotes";
import getBadges from "../functions/getBadges";

function isASCII(str, extended) {
    return (extended ? /^[\x00-\xFF]*$/ : /^[\x00-\x7F]*$/).test(str);
}

const Channels = styled.div`
    width: 100%;
    height: 2.75rem;
    display: flex;
    margin-top: 1rem;
`;

const Channel = styled.div`
    width: fit-content;
    max-height: 100%;
    font-family: "Lexend", Arial, Helvetica, sans-serif;
    border-radius: 50px;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    background-color: #282930;
    border: 2.5px solid #3e404b;
    transition: background-color 0.25s ease, border 0.25s ease;
    color: #fff;
    margin-right: 0.5rem;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: #31323c;
        /* border: 2.5px solid #31323C; */
    }

    &:active {
        background-color: #212228;
        /* border: 2.5px solid #212228; */
    }

    ${(props) =>
        props.selectedChannel.toLowerCase() === props.channel.toLowerCase() &&
        css`
            background-color: #3e404b;
        `}
`;
const Messages = styled.div`
    width: calc(100vw - 5rem);
    height: calc(100vh - 11.5rem);
    margin-top: 1rem;
    overflow: scroll;
`;

function Chat({ channels }) {
    const [selectedChannel, setSelectedChannel] = useState("");
    const [messages, setMessages] = useState([]);
    const [scroll, setScroll] = useState(true);
    const messagesListRef = useRef(null);

    const [client, setClient] = useState();
    const [channelsData, setChannelsData] = useState({});

    useEffect(() => {
        if (!client) {
            setClient(
                new tmi.Client({
                    options: { debug: false, messagesLogLevel: "info" },
                    connection: {
                        reconnect: true,
                        secure: true,
                    },
                    channels: channels,
                })
            );

            channels.map(async (channel) => {
                getUserId(channel.slice(1, channel.length)).then((data) => {
                    if (data) {
                        setChannelsData((c) => {
                            var temp = c;
                            temp[channel.slice(1, channel.length)] = {
                                created_at: data["created_at"],
                                display_name: data["display_name"],
                                id: data["id"],
                                bio: data["description"],
                                logo: data["profile_image_url"],
                                type: data["type"],
                                name: data["display_name"],
                            };

                            return temp;
                        });

                        getEmotes(
                            "y4sxj0tgauj514q76ru6wdwc9kxn7s",
                            data["id"],
                            data["display_name"]
                        ).then((emotes) => {
                            if (emotes) {
                                setChannelsData((c) => {
                                    var temp = c;
                                    temp[channel.slice(1, channel.length)][
                                        "emotes"
                                    ] = emotes;

                                    return temp;
                                });
                            }
                        });

                        getBadges(data["id"]).then((badges) => {
                            if (badges) {
                                setChannelsData((c) => {
                                    var temp = c;
                                    temp[channel.slice(1, channel.length)][
                                        "badges"
                                    ] = badges;

                                    return temp;
                                });
                            }
                        });
                    }
                });
            });
        }

        return async () => {
            setClient(null);
        };
    }, []);

    useEffect(() => {
        if (client) {
            client.connect().catch(console.error);

            client.on("timeout", (channel, username) => {
                setMessages((prev) => {
                    return prev.filter((a) => a.tags["username"] !== username);
                });
            });

            client.on("ban", (channel, username) => {
                setMessages((prev) => {
                    return prev.filter((a) => a.tags["username"] !== username);
                });
            });

            client.on(
                "subscription",
                (channel, username, method, message, userstate) => {
                    setMessages((msgs) =>
                        msgs.concat({
                            channel: channel,
                            subMessage: `${username} has subscribed with ${method.plan}`,
                            message: message,
                            deleted: false,
                            sub: true,
                            tags: {},
                        })
                    );
                }
            );

            client.on(
                "resub",
                (channel, username, months, message, userstate, methods) => {
                    setMessages((msgs) =>
                        msgs.concat({
                            channel: channel,
                            subMessage: `${username} has subscribed for ${months} Months with ${methods.plan}`,
                            message: message,
                            deleted: false,
                            sub: true,
                            tags: {},
                        })
                    );
                }
            );

            client.on(
                "subgift",
                (
                    channel,
                    username,
                    streakMonths,
                    recipient,
                    methods,
                    userstate
                ) => {
                    setMessages((msgs) =>
                        msgs.concat({
                            channel: channel,
                            subMessage: `${username} has gifted a sub to ${recipient}`,
                            message: "",
                            deleted: false,
                            sub: true,
                        })
                    );
                }
            );

            client.on("message", (channel, tags, message, self) => {
                var badgeInfo = tags["badge-info"];
                var badgesInfoRaw = tags["badge-info-raw"];
                var badges = tags["badges"];
                var badgesRaw = ["badges-raw"];
                var color = tags["color"];
                var displayName = tags["display-name"];
                var twitchEmotes = tags["emotes"];
                var flags = tags["flags"];
                var id = tags["id"];
                var messageType = tags["message-type"];
                var mod = tags["mod"];
                var roomId = tags["room-id"];
                var subscriber = tags["subscriber"];
                var timestamp = tags["tmi-sent-ts"];
                var turbo = tags["turbo"];
                var userId = tags["user-id"];
                var userType = tags["user-type"];
                var username = tags["username"];

                var formattedMsg = message;

                if (twitchEmotes) {
                    Object.keys(twitchEmotes).map((tw, index) => {
                        var target = message
                            .split("")
                            .slice(
                                Number(tags.emotes[tw][0].split("-")[0]),
                                Number(tags.emotes[tw][0].split("-")[1]) + 1
                            );
                        twitchEmotes[tw].map((e, i) => {
                            if (
                                !Number(twitchEmotes[tw][i].split("-")[0] + 1)
                            ) {
                                return;
                            }
                            formattedMsg = formattedMsg.replace(
                                target.join(""),
                                ` //EMOTE-${Object.keys(twitchEmotes)[index]} `
                            );
                        });
                    });
                }

                //   if(username === "dieserobin" || username === "flashskynews" || username === "phoenixnico" || mod) {
                //     if(message.split(" ")[0] === "@pin") {
                //       setPinned({channel:channel,tags:tags,message:formattedMsg.slice(4,formattedMsg.length),self:self});
                //     } else if(message.split(" ")[0] === "@unpin") {
                //       setPinned(null);
                //     }
                //   }

                if (isASCII(message, true)) {
                    // if(document.body.classList.contains("overlay")) {
                    //   if(username === "flashskynews" && username === "flashskynews" && message.split(" ")[0] === "[Reeze]" || username === "flashskynews" && message.split(" ")[0] === "[Kevin]" || username === "flashskynews" && message.split(" ")[0] === "[RTMP]") {
                    //     return;
                    //   }
                    // }

                    setMessages((msgs) =>
                        msgs.concat({
                            channel: channel,
                            tags: tags,
                            message: formattedMsg,
                            self: self,
                            deleted: false,
                        })
                    );
                }
            });
        }
    }, [client]);

    useEffect(() => {
        if (scroll) {
            messagesListRef.current?.scrollTo(
                0,
                messagesListRef.current?.scrollHeight
            );
        } else if (document.body.classList.contains("overlay")) {
            messagesListRef.current?.scrollTo(
                0,
                messagesListRef.current?.scrollHeight
            );
        }

        if ((messages.length > 100 && scroll) || messages.length > 150) {
            setMessages((m) => {
                m.shift();
                for (
                    var i = 0;
                    i < (messages.length > 300 ? 300 - messages.length : 0);
                    i++
                ) {
                    m.shift();
                }
                return m;
            });
        }
    }, [messages]);

    return (
        <>
            <Channels>
                {channels.map((channel) => {
                    return (
                        <>
                            <Channel
                                key={channel}
                                channel={channel}
                                selectedChannel={selectedChannel}
                                onClick={(e) => {
                                    if (selectedChannel === channel) {
                                        setSelectedChannel("");
                                    } else {
                                        setSelectedChannel(channel);
                                    }
                                }}
                            >
                                {channel.slice(1, channel.length)}
                            </Channel>
                        </>
                    );
                })}
            </Channels>

            <Messages
                ref={messagesListRef}
                onScroll={(e) => {
                    if (
                        e.target.scrollTop <
                        e.target.scrollHeight - e.target.clientHeight - 250
                    ) {
                        setScroll(false);
                    } else {
                        setScroll(true);
                    }
                }}
            >
                {messages.map((message) => {
                    if (
                        selectedChannel &&
                        selectedChannel === message.channel
                    ) {
                        return (
                            <Message
                                key={message.id}
                                data={message}
                                channel={
                                    channelsData[
                                        message.channel.slice(
                                            1,
                                            message.channel.length
                                        )
                                    ]
                                }
                                showIcon={channels.length > 1 ? true : false}
                            ></Message>
                        );
                    } else if (selectedChannel === "") {
                        return (
                            <Message
                                key={message.id}
                                data={message}
                                channel={
                                    channelsData[
                                        message.channel.slice(
                                            1,
                                            message.channel.length
                                        )
                                    ]
                                }
                                showIcon={channels.length > 1 ? true : false}
                            ></Message>
                        );
                    }
                })}
            </Messages>
        </>
    );
}

export default Chat;
