import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import tmi from "tmi.js";
import Message from "./components/Message";

import getUserId from "./functions/getUserId";
import getEmotes from "./functions/getEmotes";
import getBadges from "./functions/getBadges";
import { useParams } from "react-router-dom";

function isASCII(str, extended) {
  return (extended ? /^[\x00-\xFF]*$/ : /^[\x00-\x7F]*$/).test(str);
}

const Messages = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: scroll;
`;

function Overlay() {
  const [channels, setChannels] = useState([]);
  const [messages, setMessages] = useState([]);
  const [scroll, setScroll] = useState(true);
  const messagesListRef = useRef(null);

  const [client, setClient] = useState();
  const [channelsData, setChannelsData] = useState({});

  const { list } = useParams();

  useEffect(() => {
    setChannels(list.split(","));
    document.body.classList.add("overlay");

    if (!client) {
      setClient(
        new tmi.Client({
          options: { debug: false, messagesLogLevel: "info" },
          connection: {
            reconnect: true,
            secure: true,
          },
          channels: list.split(","),
        })
      );

      list.split(",").map(async (channel) => {
        getUserId(channel).then((data) => {
          if (data) {
            setChannelsData((c) => {
              var temp = c;
              temp[channel] = {
                created_at: data["created_at"],
                display_name: data["display_name"],
                id: data["id"],
                bio: data["description"],
                logo: data["profile_image_url"],
                type: data["type"],
                name: data["display_name"],
              };

              return temp;
            });

            getEmotes(
              "y4sxj0tgauj514q76ru6wdwc9kxn7s",
              data["id"],
              data["display_name"]
            ).then((emotes) => {
              if (emotes) {
                setChannelsData((c) => {
                  var temp = c;
                  temp[channel]["emotes"] = emotes;

                  return temp;
                });
              }
            });

            getBadges(data["id"]).then((badges) => {
              if (badges) {
                setChannelsData((c) => {
                  var temp = c;
                  temp[channel]["badges"] = badges;

                  return temp;
                });
              }
            });
          }
        });
      });
    }

    return async () => {
      setClient(null);
    };
  }, []);

  useEffect(() => {
    if (client) {
      client.connect().catch(console.error);

      client.on("timeout", (channel, username) => {
        setMessages((prev) => {
          return prev.filter((a) => a.tags["username"] !== username);
        });
      });

      client.on("ban", (channel, username) => {
        setMessages((prev) => {
          return prev.filter((a) => a.tags["username"] !== username);
        });
      });

      client.on("message", (channel, tags, message, self) => {
        var badgeInfo = tags["badge-info"];
        var badgesInfoRaw = tags["badge-info-raw"];
        var badges = tags["badges"];
        var badgesRaw = ["badges-raw"];
        var color = tags["color"];
        var displayName = tags["display-name"];
        var twitchEmotes = tags["emotes"];
        var flags = tags["flags"];
        var id = tags["id"];
        var messageType = tags["message-type"];
        var mod = tags["mod"];
        var roomId = tags["room-id"];
        var subscriber = tags["subscriber"];
        var timestamp = tags["tmi-sent-ts"];
        var turbo = tags["turbo"];
        var userId = tags["user-id"];
        var userType = tags["user-type"];
        var username = tags["username"];

        var formattedMsg = message;

        if (username == "titlechange_bot") return;

        if (twitchEmotes) {
          Object.keys(twitchEmotes).map((tw, index) => {
            var target = message
              .split("")
              .slice(
                Number(tags.emotes[tw][0].split("-")[0]),
                Number(tags.emotes[tw][0].split("-")[1]) + 1
              );
            twitchEmotes[tw].map((e, i) => {
              if (!Number(twitchEmotes[tw][i].split("-")[0] + 1)) {
                return;
              }
              formattedMsg = formattedMsg.replace(
                target.join(""),
                ` //EMOTE-${Object.keys(twitchEmotes)[index]} `
              );
            });
          });
        }

        //   if(username === "dieserobin" || username === "flashskynews" || username === "phoenixnico" || mod) {
        //     if(message.split(" ")[0] === "@pin") {
        //       setPinned({channel:channel,tags:tags,message:formattedMsg.slice(4,formattedMsg.length),self:self});
        //     } else if(message.split(" ")[0] === "@unpin") {
        //       setPinned(null);
        //     }
        //   }

        if (isASCII(message, true)) {
          // if(document.body.classList.contains("overlay")) {
          //   if(username === "flashskynews" && username === "flashskynews" && message.split(" ")[0] === "[Reeze]" || username === "flashskynews" && message.split(" ")[0] === "[Kevin]" || username === "flashskynews" && message.split(" ")[0] === "[RTMP]") {
          //     return;
          //   }
          // }

          setMessages((msgs) =>
            msgs.concat({
              channel: channel,
              tags: tags,
              message: formattedMsg,
              self: self,
              deleted: false,
            })
          );
        }
      });
    }
  }, [client]);

  useEffect(() => {
    if (scroll) {
      messagesListRef.current?.scrollTo(
        0,
        messagesListRef.current?.scrollHeight
      );
    } else if (document.body.classList.contains("overlay")) {
      messagesListRef.current?.scrollTo(
        0,
        messagesListRef.current?.scrollHeight
      );
    }

    if (messages.length > 50 && scroll) {
      setMessages((m) => {
        m.shift();
        return m;
      });
    }
  }, [messages]);

  return (
    <>
      <Messages
        ref={messagesListRef}
        onScroll={(e) => {
          setScroll(true);
        }}
      >
        {messages.map((message) => {
          return (
            <Message
              big
              key={message.id}
              data={message}
              channel={
                channelsData[message.channel.slice(1, message.channel.length)]
              }
              showIcon={channels.length > 1 ? true : false}
            ></Message>
          );
        })}
      </Messages>
    </>
  );
}

export default Overlay;
