import React, { usetState, useEffect, useState } from "react";
import "./App.css";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import Channels from "./components/Channels";

import AddIcon from "./assets/svg/add.svg";
import AddPopup from "./components/AddPopup";
import TwitterIcon from "./assets/svg/twitter.svg";
import RsofLogo from "./assets/svg/rsof_light-logo.svg";
import Chat from "./components/Chat";

const Container = styled.div`
  padding: 1rem 2rem 1rem 2rem;
  transition: opacity 0.5s ease;
`;
const Title = styled.h1`
  color: #fff;
  font-family: "Lexend", Arial, sans-serif;
  user-select: none;
`;
const Button = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  user-select: none;
  background-color: #8490FF;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0px 0px 2.5px 2.5px rgba(0,0,0,.125);
  transition: transform .25s ease, background-color .25s ease;
  cursor: pointer;

  &:hover {
    background-color: #7482FF;
  }

  &:active {
    background-color: #606BCC;
  }

  ${(props) =>
    props.add &&
    css`
      width: 2rem;
      height: 2rem;
      position: fixed;
      bottom: 2rem;
      right: 2rem;
      user-select: none;
      background-color: #8490ff;
      padding: 1rem;
      border-radius: 50%;
      box-shadow: 0px 0px 2.5px 2.5px rgba(0, 0, 0, 0.125);
      transition: transform 0.25s ease, background-color 0.25s ease;
      cursor: pointer;
    `}}

  ${(props) =>
    props.open &&
    css`
      width: fit-content;
      position: fixed;
      top: 2.25rem;
      right: 2rem;
      user-select: none;
      background-color: #8490ff;
      padding: 0.5rem 1.5rem 0.5rem 1.5rem;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Lexend", Arial, Helvetica, sans-serif;
      box-shadow: 0px 0px 2.5px 2.5px rgba(0, 0, 0, 0.125);
      transition: transform 0.25s ease, background-color 0.25s ease;
      cursor: pointer;

      ${(props) =>
        props.overlay &&
        css`
          @media screen and (max-width: 650px) {
            display: none;
          }
        `}

      ${(props.channels.length <= 0 || props.channels.length > 20) &&
      css`
        background-color: #606bcc;
        cursor: not-allowed;
      `}
    `}
`;

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

const Twitter = styled.div`
  position: fixed;
  bottom: 2rem;
  display: flex;
  align-items: center;
  font-family: "Lexend", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #fff;
  gap: 0.25rem;
  cursor: pointer;
  user-select: none;

  & img {
    margin-left: 0.25rem;
    height: 1.5rem;
  }

  @media screen and (max-width: 650px) {
    width: 70vw;
    font-size: 16px;
    bottom: 3rem;
    left: 1rem;
  }
`;

function App() {
  const [currentPage, setCurrentPage] = useState("setup");
  const [channels, setChannels] = useState(["Papaplatte", "Reeze"]);

  const [popupShown, setPopupShown] = useState(false);

  return (
    <div className="App">
      <CSSTransition
        in={currentPage === "setup"}
        unmountOnExit
        onExit={() => {}}
        onEnter={() => {}}
        timeout={500}
        classNames="fade-anim"
      >
        <Container>
          <Title>Chat</Title>

          <Channels channels={channels} setChannels={setChannels}></Channels>

          <div>
            <Title>Test the successor in the beta</Title>
            <p style={{ color: "white" }}>
              The successor now supports the "Kick" platform, provides live
              emote updates through 7TV, and offers a variety of customization
              options for a more personalized experience.
            </p>
            <button
              style={{
                color: "white",
                backgroundColor: "#8490FF",
                padding: "0.5rem 1rem",
                borderRadius: "8px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://multichat.robin.software", "_blank");
              }}
            >
              The NEW MultiChat
            </button>
          </div>

          <Button onClick={() => setPopupShown(true)} add>
            <img src={AddIcon} alt="Add" />
          </Button>
          <Button
            onClick={() => {
              if (channels.length > 0 && channels.length <= 20) {
                setCurrentPage("chat");
                setPopupShown(false);
              }
            }}
            channels={channels}
            open
          >
            Open Chat
          </Button>

          <Twitter
            onClick={(e) => {
              window.open("https://robin.software", "_blank");
            }}
          >
            <img src={RsofLogo} alt="robin.software" />
          </Twitter>
        </Container>
      </CSSTransition>
      <CSSTransition
        in={currentPage === "chat"}
        unmountOnExit
        onExit={() => {}}
        onEnter={() => {}}
        timeout={500}
        classNames="fade-anim"
      >
        <Container>
          <Title
            onClick={() => {
              setCurrentPage("setup");
            }}
          >
            Chat
          </Title>
          <Button
            onClick={() => {
              if (channels.length > 0 && channels.length <= 20) {
                navigator.clipboard.writeText(
                  `${window.location.href}overlay/${channels
                    .join(",")
                    .replaceAll("#", "")}`
                );
              }
            }}
            channels={channels}
            open
            overlay
          >
            Copy Overlay Link
          </Button>
          <Chat channels={channels} />
        </Container>
      </CSSTransition>
      <CSSTransition
        in={popupShown === true}
        unmountOnExit
        onExit={() => {}}
        onEnter={() => {}}
        timeout={500}
        classNames="popup-anim"
      >
        <AddPopup
          close={() => {
            setPopupShown(false);
          }}
          addChannel={(channel) => {
            if (!channels.find((x) => x === channel)) {
              setChannels((x) => x.concat(channel));
            }
            setPopupShown(false);
          }}
        ></AddPopup>
      </CSSTransition>
      <CSSTransition
        in={popupShown === true}
        unmountOnExit
        onExit={() => {}}
        onEnter={() => {}}
        timeout={500}
        classNames="fade-anim"
      >
        <Background onClick={() => setPopupShown(false)}></Background>
      </CSSTransition>
    </div>
  );
}

export default App;
