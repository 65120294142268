function getUserId(username) {
  var promise = new Promise((resolve, reject) => {
    if (!username) reject("Invalid id");
    console.info(`[TWITCH] Fetching User ID of ${username}`);
    fetch(`https://chatapi.robin.software/channel/${username}`, {})
      .then((res) => res.json())
      .then((res) => {
        if (res && res["data"]) {
          resolve(res["data"]);
        } else {
          console.error("[TWITCH] No valid response");
          reject("No valid response");
        }
      })
      .catch((e) => {
        console.error(`[TWITCH] Error at fetching User ID`, e);
        reject("Error at fetching User ID");
      });
  });

  return promise;
}

export default getUserId;
