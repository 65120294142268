function getEmotes(clientId, channelId, username) {
  var promise = new Promise(async (resolve, reject) => {
    var emotes = {};

    await fetch(`https://api.7tv.app/v2/users/${channelId}/emotes`, {})
      .then((res) => res.json())
      .then((res) => {
        res.forEach((e) => {
          emotes[e.name] = e.urls[0][1];
        });
      });

    await fetch(`https://7tv.io/v3/emote-sets/global`, {})
      .then((res) => res.json())
      .then((res) => {
        res.forEach((e) => {
          emotes[e.name] = `${e.data.host.url}/${e.data.host.files[0].name}`;
        });
      });
    console.info(`[BTTV] Fetching Emotes of ${channelId}`);
    await fetch(
      `https://api.betterttv.net/3/cached/users/twitch/${channelId}`,
      {}
    )
      .then((res) => res.json())
      .then((res) => {
        if (res && res.channelEmotes && res.sharedEmotes) {
          res.channelEmotes.map((emote, i) => {
            emotes[
              emote.code
            ] = `https://cdn.betterttv.net/emote/${emote.id}/1x`;
            return emote;
          });
          res.sharedEmotes.map((emote, i) => {
            emotes[
              emote.code
            ] = `https://cdn.betterttv.net/emote/${emote.id}/1x`;
            return emote;
          });
        } else {
          console.error("[BTTV] No valid response");
          reject("No valid response");
        }
      })
      .catch((e) => {
        console.error(`[BTTV] Error at fetching Emotes`, e);
        reject("Error at fetching BTTV Emotes");
      });

    console.info(`[BTTV] Fetching Emotes Global Emotes`);
    await fetch(`https://api.betterttv.net/3/cached/emotes/global`, {})
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          res.map((emote, i) => {
            emotes[
              emote.code
            ] = `https://cdn.betterttv.net/emote/${emote.id}/1x`;
            return emote;
          });
        } else {
          console.error("[BTTV] No valid response");
          reject("No valid response");
        }
      })
      .catch((e) => {
        console.error(`[BTTV] Error at fetching Emotes`, e);
        reject("Error at fetching BTTV Emotes");
      });

    console.info(`[FFZ] Fetching Emotes of ${channelId}`);
    await fetch(`https://api.frankerfacez.com/v1/room/id/${channelId}`, {})
      .then((res) => res.json())
      .then((res) => {
        if (res && res.sets) {
          res.sets[Object.keys(res.sets)[0]].emoticons.map((emote) => {
            emotes[
              emote.name
            ] = `https://cdn.frankerfacez.com/emote/${emote.id}/1`;
            return emote;
          });
        } else {
          console.error("[FFZ] No valid response");
          reject("No valid response");
        }
      })
      .catch((e) => {
        console.error(`[FFZ] Error at fetching Emotes`, e);
        reject("Error at fetching FFZ Emotes");
      });

    console.info(`[FFZ] Fetching Global Emotes`);
    await fetch(`https://api.frankerfacez.com/v1/set/global`, {})
      .then((res) => res.json())
      .then((res) => {
        if (res && res.sets) {
          res.sets[Object.keys(res.sets)[0]].emoticons.map((emote) => {
            emotes[
              emote.name
            ] = `https://cdn.frankerfacez.com/emote/${emote.id}/1`;
            return emote;
          });
        } else {
          console.error("[FFZ] No valid response");
          reject("No valid response");
        }
      })
      .catch((e) => {
        console.error(`[FFZ] Error at fetching Emotes`, e);
        reject("Error at fetching FFZ Emotes");
      });

    resolve(emotes);
  });

  return promise;
}

export default getEmotes;
