import React, { useState, useEffect } from "react";
import styled from "styled-components";

import RemoveIcon from "../assets/svg/remove.svg";

const Channel = styled.div`
    padding: 1rem;
    background-color: #282930;
    border: 2.5px solid #3e404b;
    border-radius: 10px;
    user-select: none;
    transition: background-color 0.25s ease;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;

    &:hover {
        background-color: #3e404b;
    }
`;
const Name = styled.span`
    font-family: "Lexend", Arial, sans-serif;
    font-size: 18px;
    color: #fff;
`;
const Remove = styled.div`
    background-color: #3e404b;
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1rem;
    border-radius: 5rem;
    transition: background-color 0.25s ease;

    ${Channel}:hover & {
        background-color: #282930;
        cursor: pointer;
    }

    & img {
        width: 2rem;
    }
`;

function Channels({ channels, setChannels }) {
    return (
        <>
            {channels.map((channel) => {
                return (
                    <>
                        <Channel key={channel}>
                            <Name>{channel}</Name>

                            <Remove
                                onClick={(e) => {
                                    setChannels((x) => {
                                        return x.filter(function (value) {
                                            return value !== channel;
                                        });
                                    });
                                }}
                            >
                                <img
                                    onDragStart={(e) => {
                                        e.preventDefault();
                                    }}
                                    draggable="false"
                                    src={RemoveIcon}
                                    alt="Remove"
                                />
                            </Remove>
                        </Channel>
                    </>
                );
            })}
        </>
    );
}

export default Channels;
